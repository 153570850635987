// 外部接続先が全て本番環境の場合
export const region = 'ap-northeast-1';
export const stage = 'production';
export const bkpPath = 'https://bitlock.bitkey.cloud/v1';
export const ifBaseApiPath = 'https://api.cde.sumamoru.entryservice.jp/link/api/v1';
export const bitlockApiPath = 'https://us-central1-bitlock-production.cloudfunctions.net/ext-v1';
export const bitlockVoApiPath = 'https://us-central1-bitlock-production.cloudfunctions.net/ext-vo';
export const bitlockVoApiKey = `iuefvjojhfviufaepo48575.4592347525iohgfkrr94758275907903u4175`;
export const bitlockVoVerifyIdentityApiKey = `uvsagvbg6234872355906295vhbjnfjsdchn723t5825935429dthrvbwehrvb257`;

// サイトでの基本的な動的変数の定義
export const basicSiteVariablesDefinition = {
  projectId: '48566ff5-3e7c-4c1f-ac2f-73cef2009f64',
  linkageDateFormat: 'YYYY-MM-DD'
};
